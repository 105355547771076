<template>

  <div class="row" ref="itemView">

    <div class="col-12">

      <card>
        <div slot="header">
          <div class="header-content">
            <div class="form-title">
              <h4 class="card-title">
                {{ formTitle }}
              </h4>
            </div>
          </div>
        </div>

        <div id="contactus-message" class="card-body">
          <div class="container">
            <div class="row contact-info">
              <div class="col-12 col-md-12 col-lg-12">
               <div class="member-box">
                 <span class="title-label">Personal Information</span>
                 <div class="row">
                   <div class="col-md-9">
                     <div class="row">
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">Full Name</span>
                           <span class="category-value">  {{ getValueOrDash(itemDetails, 'first_name') }} {{ getValueOrDash(itemDetails, 'last_name') }}</span>
                         </p>
                       </div>
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">Birth Date</span>
                           <span class="category-value"> {{ getValueOrDash(itemDetails, 'dob') }}</span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="typo-line">
                         <p>
                           <span class="category category-2">Grade</span>
                           <span class="category-value"> {{ getValueOrDash(itemDetails, 'grade') }}</span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">Parent Email</span>
                           <span v-if="itemDetails && itemDetails.parent" class="category-value"> {{ itemDetails.parent.email }}</span>
                         </p>
                       </div>
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">Parent Phone</span>
                           <span v-if="itemDetails && itemDetails.parent" class="category-value">{{ itemDetails.parent.phone }}</span>
                         </p>
                       </div>
                     </div>
                   </div>
                   <div class="col-md-3">
                     <img class="member-profile-image" :src="imagePath + getValueOrDash(itemDetails, 'profile_image')" alt="Profile Image"/>
                   </div>
                 </div>
               </div>
                <div class="member-box">
                  <span class="title-label">Address Information</span>
                  <div class="row">
                    <div class="col-md-6 typo-line">
                      <p>
                        <span class="category category-2">Address</span>
                        <span v-if="itemDetails && itemDetails.parent" class="category-value"> {{ itemDetails.parent.address }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="member-box">
                  <span class="title-label">Other Information</span>
                  <div class="row">
                    <div class="typo-line">
                      <p v-if="itemDetails">
                        <span class="category category-2">Food Preference</span>
                        <span class="category-value" v-for="preference in itemDetails.food_preference">{{ preference.title }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="typo-line">
                      <p v-if="itemDetails">
                        <span class="category category-2">Allergies</span>
                        <span class="category-value" v-for="allergy in itemDetails.allergies">{{ allergy.title }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="typo-line">
                      <p>
                        <span class="category category-2">Created At</span>
                        <span class="category-value"> {{  new Date(itemDetails.created_at).toLocaleString() }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div class="member-box">
                <span class="title-label">Current Subscriptions </span>
                <div v-if="itemDetails">
                  <div class="message-content" v-for="subscription in itemDetails.currentSubscriptions" style="border-bottom: 1px solid #ccc;margin-bottom: 20px">

                    <!--                  //Start & End Date-->
                    <div class="">
                      <div class="">
                        <div class="row">
                          <div class="col-md-6">
                            <p>
                              <span class="category category-2">Start Date</span>
                              <span class="category-value" style="margin-left: 65px"> {{subscription.start_date }}</span>
                            </p>
                          </div>
                          <div class="col-md-6">
                            <p>
                              <span class="category category-2">End Date</span>
                              <span class="category-value" style="margin-left: 65px"> {{subscription.end_date }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                  //created at-->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Subscription date</span>
                          <span class="category-value" style="margin-left: 73px"> {{subscription.created_at }}</span>
                        </p>
                      </div>
                    </div>


                    <!--                  //Status-->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Status</span>
                          <span class="category-value" style="margin-left: 73px"> {{subscription.status }}</span>
                        </p>
                      </div>
                    </div>
                    <!-- //packaging  -->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Food Packaging</span>
                          <span class="category-value" style="margin-left: 73px"> {{subscription.package.name }}</span>
                        </p>
                      </div>
                    </div>

                    <!--                  //Price-->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Total Amount</span>
                          <span class="category-value" style="margin-left: 73px"> {{subscription.price }}$</span>
                        </p>
                      </div>
                    </div>
              <!-- Totals -->
              <div class="member-box">
                        <span class="title-label" style="width: fit-content;">Price Summary</span>
                        <div class="">
                          <div class="typo-line">
                            <p>
                              <span class="category category-2">Items Price</span>
                              <span class="category-value"> {{subscription.totals.items_price }}$</span>
                            </p>
                          </div>
                        </div>
  
                        <div class="member-box small-box" v-if="subscription.totals_cms">
                            <span class="title-label">Days</span>
                          <div  v-for="total in subscription.totals_cms">
                                <p>
                                  <b>
                                    <span >{{ total.day }}: </span>
                                    <span > {{ total.price }}$</span>
                                  </b>
                                </p>
                          </div>
                        </div>
  
  
  
  
                        <div class="">
                          <div class="typo-line">
                            <p>
                              <span class="category category-2">Packaging Price</span>
                              <span class="category-value"> {{subscription.totals.packaging_price }}$</span>
                            </p>
                          </div>
                        </div>
  
                        <div class="">
                          <div class="typo-line">
                            <p>
                              <span class="category category-2">Tax Ammount</span>
                              <span class="category-value"> {{subscription.totals.tax_amount }}$</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    <!--                  //Notes-->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Notes</span>
                          <span class="category-value"> {{subscription.notes }}</span>
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-info" @click="openMenuDetailsModal(subscription.subscription_menus)"><i class="fa fa-info"></i>Show Menu </button>
                  </div>
                </div>
              </div>


              <div class="member-box">
                <span class="title-label">Last Subscriptions </span>
                <div v-if="itemDetails">
                  <div class="message-content" v-for="subscription in itemDetails.lastSubscriptions" style="border-bottom: 1px solid #ccc; margin-bottom: 20px">

                    <!--                  //Start & End Date-->
                    <div class="">
                      <div class="typo-line">
                        <div class="row">
                          <div class="col-md-6">
                            <p>
                              <span class="category category-2">Start Date</span>
                              <span class="category-value" style="margin-left: 65px"> {{subscription.start_date }}</span>
                            </p>
                          </div>
                          <div class="col-md-6">
                            <p>
                              <span class="category category-2">End Date</span>
                              <span class="category-value" style="margin-left: 65px"> {{subscription.end_date }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                  //type-->
                    <!-- <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Type</span>
                          <span class="category-value"> {{subscription.type }}</span>
                        </p>
                      </div>
                    </div> -->

                    <!--                  //Status-->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Status</span>
                          <span class="category-value"> {{subscription.status }}</span>
                        </p>
                      </div>
                    </div>

                    <!-- //packaging  -->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Food Packaging</span>
                          <span class="category-value"> {{subscription.package.name }}</span>
                        </p>
                      </div>
                    </div>

                    <!--                  //Price-->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Price</span>
                          <span class="category-value"> {{subscription.price }}$</span>
                        </p>
                      </div>
                    </div>


                    <!--                  //Notes-->
                    <div class="">
                      <div class="typo-line">
                        <p>
                          <span class="category category-2">Notes</span>
                          <span class="category-value"> {{subscription.notes }}</span>
                        </p>
                      </div>
                    </div>
                    <button class="btn btn-info" @click="openMenuDetailsModal(subscription.subscription_menus)"><i class="fa fa-info"></i>Show Menu </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer text-right">

        </div>
      </card>


    </div>
    <menu-details-modal :visible="showMenuModalVisibility"
                  :title="'Show Menu'"
                  :items="currentMenu"
                  @close="closeMenuDetailsModal()"
    >
    </menu-details-modal>
  </div>
</template>
<script>
// import { jsPDF } from "jspdf";

import MenuDetailsModal from "../../components/Modals/MenuDetailsModal";
export default {

  components: {MenuDetailsModal},

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,
      itemDetails: null,
      imagePath: process.env.VUE_APP_SERVER_IMAGE_URL,
      showMenuModalVisibility: false,
      currentMenu: [],

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.itemView
    });

    this.id = this.$route.params['id'];
    this.formTitle = "Child Details";
    if (this.id !== undefined) {
      this.getItem();
    } else {
      this.$notify({
        message: "Child Not Found",
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {

    getItem() {
      this.axios.post("children/get", {id: +this.id}).then((response) => {
        this.itemDetails = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Child Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    },
    openMenuDetailsModal(currentMenu) {
      this.currentMenu = currentMenu;
      this.showMenuModalVisibility = true;
    },
    closeMenuDetailsModal() {
      this.showMenuModalVisibility = false;
    },
  }
}
</script>

<style>
.header-content{
  display: flex;
  justify-content: space-between;
}
.typo-line{
  padding-left: 65px;
  margin-bottom: 20px;
}
.contact-info{
  margin-bottom: 20px;
}
.category{
  color: #888888;
}
.category-2{
  top: 11px !important;
}
.category-value{
  margin-left: 50px;
}
/*.message-content{*/
/*  font-size: 16px !important*/
/*}*/
.member-box {
  position: relative;
  border: 1px solid black;
  padding: 33px;
  padding-left: 30px;
  margin-bottom: 30px;
}
.small-box{
    padding: 13px;
    width: fit-content;
    margin-bottom: 13px;
  }
  .small-box p{
    font-size: 12px;
    margin: 0;
  }
.title-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
}
.member-profile-image{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
</style>
