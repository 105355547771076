<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">School List</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['schools/create'])"
                         :to="'/schools/create'"
                         class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            :key="componentKey"
            :api-url="'schools/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

              <template slot-scope="scope">

                <div v-if="column.value === 'url'">
                  {{ scope.row[column.value] }} <a v-tooltip.top-center="'Copy School registeration URL'" @click="copyUrl(scope.row[column.value])"><i class="far fa-copy"></i></a>
                </div>
                <span v-else>{{ scope.row[column.value] }}</span>

              </template>

            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['schools/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['clients/view-qrcode'])"
                   v-tooltip.top-center="'Qr Code'"
                   class="btn-primary btn-simple btn-link"
                   @click="openQrCodeModal(scope.row)"><i class="fa fa-download"></i></a>

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['schools/view-students'])"
                             v-tooltip.top-center="'View Students'"
                             class="btn-success btn-simple btn-link"
                             :to="'/schools/view-students/'+scope.row.id">
                  <i class="fa fa-eye"></i></router-link>

                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['schools/calendar'])"
                             v-tooltip.top-center="'View Calendar'"
                             class="btn-info btn-simple btn-link"
                             :to="'/schools/calendar/'+scope.row.id">
                  <i class="fa fa-calendar"></i></router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['schools/resend-welcome-email'])"
                             v-tooltip.top-center="'Resend welcome email'"
                             class="btn-success btn-simple btn-link"
                             @click="resendWelcomeEmail(scope.row.id)"
                             :disabled="submitting">
                  <i class="fa fa-envelope"></i></a>

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['schools/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/schools/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>

                  <router-link v-if="$store.getters['auth/checkAccess']('schools/change-password')"
                             v-tooltip.top-center="'Change Password'" class="btn-info btn-simple btn-link"
                             :to="'/schools/changePassword/'+scope.row.id">
                  <i class="fa fa-key"></i></router-link>

<!--                <a v-if="$store.getters['auth/haveOneOfPermissions'](['schools/delete'])"-->
<!--                   v-tooltip.top-center="'Delete'"-->
<!--                   class="btn-danger btn-simple btn-link"-->
<!--                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>-->
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this school?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

      <qrcode-modal :visible="qrCodeModalVisibility"
                    :title="'School QR Code'"
                    :item="currentObject"
                    @close="closeQrCodeModal()"
      >
      </qrcode-modal>


    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {DeleteModal as DeleteModal, NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import QrcodeModal from "../../components/Modals/QrcodeModal";


export default {
  components: {
    QrcodeModal,
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'Phone', value: 'phone', minWidth: '200', align: 'center'},
        {label: 'URL', value: 'url', minWidth: '200', align: 'center'}
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      imagePath: process.env.VUE_APP_SERVER_IMAGE_URL,
      currentObject: {},
      qrCodeModalVisibility: false,
      submitting: false,
    }

  },

  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("schools/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "School updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
        'id': this.toDeleteId,
      }
      this.axios.delete("schools/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "School deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    openQrCodeModal(currentObject) {
      this.currentObject = currentObject;
      this.qrCodeModalVisibility = true;
    },
    closeQrCodeModal() {
      this.qrCodeModalVisibility = false;
    },
    resendWelcomeEmail(id){
      this.submitting = true;
      this.axios.get('schools/resend-welcome-email/'+id)
          .then(response => {
            this.submitting = false;
              this.$notify({
                message: "Email sent successfully",
                timeout: 1000,
                type: 'success'
              })
          })
          .catch(error => {
            console.log(error)
            this.submitting = false;
            this.$notify({
                message: "There was an issue sending the email",
                timeout: 1000,
                type: 'danger'
              })
          });
      
    },
    copyUrl(mytext) {
      let url = process.env.VUE_APP_PARENT_REGISTER_URL+mytext;
      navigator.clipboard.writeText(url);
    }

  },

}
</script>
