<template>
  <el-dialog
    center
    @close="closeModal()"
    :title="title"
    :visible="visible">
      <div v-if="items" id="print">
          <div class="col-md-12">
              <div class="panel panel-default">
                  <div class="panel-body" style="text-align: center;">
                      <p></p>
                      <div class="row">
                          <div class="col-md-12">
                            <div class="menuMonth" v-for="item in items">
                              <h3 style="font-weight: bold;"><b>{{item.menu.month.replace('-01', '')}}</b></h3>
                              <div class="menuDay" v-for="meal in item.meals">
                                <label style="font-size: 17px;color: green;"><b>Day : {{ meal.menuMeal.day }}</b></label>
                                <div class="dates">
                                  <label> <b>Dates:</b> </label>
                                  <span v-for="date in meal.dates" >
                                     {{ date  }} | 
                                  </span>
                                </div>
                                <div>
                                  <span  v-if="meal.meal"><b>Meal :</b> {{ meal.meal.name }} -  <span> {{ meal.is_adult_portion ? meal.meal.adult_portion_price : meal.meal.price }}$ </span> - <span> <b> {{ meal.is_adult_portion ? 'Adult' :  'Child'}} Portion</b> </span> <br> </span>
                                  
                                  <span v-if="meal.topping"> <b>Topping :</b> {{ meal.topping.name }} - <span> {{ meal.topping.price }}$ </span> <br></span> 
                                  
                                  <span  v-if="meal.replacement_meal"><b>Replacement Meal :</b> {{ meal.replacement_meal.name }} - <span> {{ meal.is_adult_portion ?  eal.replacement_meal.adult_portion_price : meal.replacement_meal.price }}$ </span> -  <span> <b> {{ meal.is_adult_portion ? 'Adult' :  'Child'}} Portion</b> </span> <br> </span>
                                  
                                  <span v-if="meal.replacement_topping"> <b>ReplacementTopping :</b> {{ meal.replacement_topping.name }} - <span> {{ meal.replacement_topping.price }}$ </span></span> 

                                  
                                  <div v-if="meal.beverages.length">
                                    <span><b>Beverages:</b> </span>
                                    <div v-for="beverage in meal.beverages">
                                    {{beverage.name }} - <span> {{ beverage.price }}$ </span>
                                  </div>
                                  </div>
                                  <div v-if="meal.addons.length">
                                    <span><b>Add-Ons:</b> </span>
                                    <div v-for="addon in meal.addons">
                                    {{addon.name }} - <span> {{ meal.is_adult_portion ? addon.adult_portion_price :addon.price }}$ </span> - <span>  <b>{{ meal.is_adult_portion ? 'Adult' :  'Child'}} Portion</b></span>
                                  </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
            </span>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: 'menu-details-modal',

  components: {
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    title: {
      type: String,
      default: 'Menu Details'
    },
    items: {
      type: Array,
      default: function (){return []}
    },
  },
    mounted(){
    },
  methods: {

    closeModal() {
      this.$emit('close');
    },

    confirmModal() {
      this.$emit('confirm');
    },
      print(){
          const prtHtml = document.getElementById('print').innerHTML;
          let stylesHtml = '';
          for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
              stylesHtml += node.outerHTML;
          }
          var mywindow = window.open('', 'PRINT');

          mywindow.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}
                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

          mywindow.document.close();
          mywindow.focus();

          mywindow.onload = function () {
              mywindow.print();
          }

          return true;
      }

  }

}
</script>

<style>
    .line {
        position: absolute;
        left: 49%;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #ccc;
        z-index: 1;
    }

    .wordwrapper {
        text-align: center;
        height: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -12px;
        z-index: 2;
    }

    .word {
        color: #ccc;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 3px;
        font: bold 12px arial,sans-serif;
        background: #fff;
    }
    .google_secret{
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .el-dialog--center .el-dialog__footer {
        text-align: right;
    }
    .menuMonth{
      border-bottom: 1px solid #ccc;
    }
    .menuDay{
      margin-bottom: 15px;
    }
</style>
